/**
 * The Type of Accent
 */
export type AccentProps = 'org' | 'adopt' | 'care';
/**
 * The Key of Accent
 */
type AccentKeys = 'ORG' | 'ADOPT' | 'CARE';

export const accentProps: Record<AccentKeys, AccentProps> = {
  ORG: 'org',
  ADOPT: 'adopt',
  CARE: 'care',
};
