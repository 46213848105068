import { createContext, ReactNode, useContext, useReducer } from 'react';

/**
 * Toast Type Types of Toasts Allowed
 *
 * @typedef {ToastType}
 */
export type ToastType = 'success' | 'error' | 'info' | 'warning';

/** Toast Action Types Actions Allowed for Toast */
export type ToastActionType = 'ADD_TOAST' | 'DELETE_TOAST';

/**
 * Toast Toast Type
 *
 * @typedef {Toast}
 */
export type Toast = {
  /** Id of Toast */
  id: string;
  /** Message to Display */
  message?: string;
  /** If the Toast is Persistent */
  persistent?: boolean;
  /** If the Toast should show an Icon */
  showIcon?: boolean;
  /** Type of Toast */
  type: ToastType;
};

/**
 * Toast State State of the Toasts
 *
 * @interface
 */
interface IToastState {
  /** Array of Toasts */
  toasts: Toast[];
}

/**
 * Toast Initial State Value
 *
 * @constant
 */
export const toastInitialValue: IToastState = {
  toasts: [],
};

/**
 * Toast Init Config
 *
 * @param {IToastState} state - The State to Init the Config with
 * @returns {object} - The Initial Config
 */
export const initConfig = (state: IToastState) => ({
  ...toastInitialValue,
  ...state,
});

/**
 * IToastAction Interface for Toast Actions
 *
 * @interface
 */
export interface IToastAction {
  /** Type of Action */
  type: ToastActionType;
  /** Toast to Add or Delete */
  toast: Toast;
}

/**
 * Toast Context Used for creating the Context Object for the useToastContext
 * Function
 *
 * @constant
 */
export const ToastContext = createContext<{
  /** The State of the Toasts */
  state: IToastState;
  /** The Dispatch Function for the Toasts */
  dispatch: React.Dispatch<IToastAction>;
}>({
  state: toastInitialValue,
  /** @returns {null} - Null */
  dispatch: () => null,
});

/**
 * Toast Reducer Reducer for adding and removing Toasts
 *
 * @param {IToastState} state - The State of the Toasts
 * @param {IToastAction} action - The Action to Perform on the Toasts
 * @returns {object} - The Updated State
 */
export function ToastReducer(state: IToastState, action: IToastAction) {
  switch (action.type) {
    case 'ADD_TOAST': {
      return {
        ...state,
        toasts: [...state.toasts, action.toast],
      };
    }
    case 'DELETE_TOAST': {
      const updatedToasts = state.toasts.filter((e) => e.id != action.toast.id);
      return {
        ...state,
        toasts: updatedToasts,
      };
    }
  }
}

/**
 * IToast Provider The Interface for what the Toast Provider will be expecting
 *
 * @interface
 */
export interface IToastProvider {
  /** The Children to Provide Context to */
  children: ReactNode;
}

/**
 * Toast Provider Used to provide the ability to use Toasts to any children of
 * the provider.
 *
 * @param {IToastProvider} children - The children this will be providing
 *   Context to.
 * @returns {Element} ToastContext
 */
export function ToastProvider({ children }: IToastProvider): JSX.Element {
  const [state, dispatch] = useReducer(ToastReducer, toastInitialValue);

  return (
    <ToastContext.Provider value={{ state, dispatch }}>
      {children}
    </ToastContext.Provider>
  );
}

/**
 * Use Toast Context Used to allow components to interact with the Toast Context
 *
 * @returns {ToastContext} ToastContext
 */
export const useToastContext = () => useContext(ToastContext);
