/**
 * This file contains the context object for the accent value. The accent value
 * is used to introduce variations to components styling in a global way to
 * ensure consistency, and to avoid prop drilling. We currently have 3 main
 * accents: care, adopt and org/lost
 */
import { AccentProps, accentProps } from '@/lib/constants/constants/accents';
import { createContext, useContext, useState } from 'react';

/** The context object for the accent value */
type AccentValueProps = {
  /** The current accent value */
  accent: AccentProps;
  /** Update the accent value */
  updateAccent: (newAccent: AccentProps) => void;
};

export const AccentContext = createContext<AccentValueProps | null>(null);

/**
 * Custom hook to access the current accent value from the AccentContext.
 *
 * @returns {string} - The current accent value.
 */
export const useAccent = () => {
  const currentAccentContext = useContext(AccentContext);
  if (!currentAccentContext) {
    throw new Error('useAccent must be used within an AccentProvider');
  }
  return currentAccentContext;
};

/**
 * The AccentProvider component
 *
 * @param {object} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - The child components.
 * @param {AccentProps} props.defaultAccent - The default accent value.
 * @returns {React.ReactNode} The rendered component.
 */
const AccentProvider: React.FC<
  React.PropsWithChildren & {
    /** The default accent value */
    defaultAccent?: AccentProps;
  }
> = ({ children, defaultAccent }) => {
  // State to hold the current accent value
  const [accent, setAccent] = useState<AccentProps>(
    defaultAccent || accentProps.ORG
  );

  /**
   * Updates the accent value.
   *
   * @param {string} newAccent - The new accent value.
   * @returns {void}
   */
  const updateAccent = (newAccent: AccentProps) => {
    setAccent(newAccent);
  };

  // Value object to be passed to the context provider
  const accentValue: AccentValueProps = {
    accent,
    updateAccent,
  };

  return (
    // Provide the AccentContext with the accentValue
    <AccentContext.Provider value={accentValue}>
      {children}
    </AccentContext.Provider>
  );
};

export default AccentProvider;
