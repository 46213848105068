import { createContext, useContext, useState } from 'react';

/** The context object for the overlay value */
type OverlayValueProps = {
  /** The current overlay value */
  show: boolean;
  /** Update the overlay value */
  updateShow: (newOverlay: boolean) => void;
};

const OverlayContext = createContext({} as OverlayValueProps);

/**
 * Custom hook to access the current overlay value from the OverlayContext.
 *
 * @returns {boolean} - The current overlay value.
 */
export const useOverlay = () => useContext(OverlayContext);

/**
 * Generates a function comment for the given function body.
 *
 * @param {object} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - The child components.
 * @param {boolean} props.defaultOverlay - The default overlay value.
 * @returns {React.ReactNode} The rendered component.
 */
const OverlayProvider: React.FC<
  React.PropsWithChildren<{
    /** The default overlay value */
    defaultOverlay?: boolean;
  }>
> = ({ children, defaultOverlay }) => {
  // State to hold the current overlay value
  const [show, setShow] = useState<boolean>(defaultOverlay || false);

  /**
   * Updates the overlay value.
   *
   * @param {string} newOverlay - The new overlay value.
   * @returns {void}
   */
  const updateShow = (newOverlay: boolean) => {
    setShow(newOverlay);
  };

  // Value object to be passed to the context provider
  const overlayValue: OverlayValueProps = {
    show,
    updateShow,
  };

  return (
    // Provide the OverlayContext with the overlayValue
    <OverlayContext.Provider value={overlayValue}>
      {children}
    </OverlayContext.Provider>
  );
};

export default OverlayProvider;
