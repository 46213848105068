import { PetcoLoveSDK } from '@petcolove/lost--client--api-sdk';

/**
 * Initialize the SDK
 *
 * @returns {PetcoLoveSDK} The SDK instance
 */
export function initSDK(): PetcoLoveSDK {
  /**
   * The PLL API base URL
   *
   * @constant {string | undefined} pllApiBaseUrl
   */
  const pllApiBaseUrl =
    process.env.NEXT_PUBLIC_PLL_API_BASE_URL ??
    process.env.STORYBOOK_PLL_API_BASE_URL;
  /**
   * The PLL Websocket base URL
   *
   * @constant {string | undefined} pllWebsocketBaseUrl
   */
  const pllWebsocketBaseUrl =
    process.env.NEXT_PUBLIC_PLL_WEBSOCKET_BASE_URL ??
    process.env.STORYBOOK_PLL_WEBSOCKET_BASE_URL;

  if (!pllApiBaseUrl) {
    throw new Error('NEXT_PUBLIC_PLL_API_BASE_URL is not defined');
  }

  if (!pllWebsocketBaseUrl) {
    throw new Error('NEXT_PUBLIC_PLL_WEBSOCKET_BASE_URL is not defined');
  }

  const sdk = new PetcoLoveSDK(pllApiBaseUrl, pllWebsocketBaseUrl);

  return sdk;
}

export const sdk: PetcoLoveSDK = initSDK();
