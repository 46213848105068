/**
 * Get the expiration date from the data.
 *
 * @param {object | null} data - The data.
 * @returns {Date | null} - The expiration date or null if not found.
 */
function getExpirationDate(
  data: { [key: string]: unknown } | null
): Date | null {
  if (
    data !== null &&
    'expiration' in data &&
    data?.expiration &&
    typeof data.expiration === 'string'
  ) {
    /**
     * The expiration date from the data.
     *
     * @constant {Date} expirationDate
     */
    const expirationDate = new Date(data.expiration);

    if (!isNaN(expirationDate.getTime())) {
      return expirationDate;
    }
    console.error('Invalid expiration date: ', data.expiration);
  }

  return null;
}

export default getExpirationDate;
